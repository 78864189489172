import { GET, POST, PUT, DELETE } from '../utils/request';
import { API_PREFIX } from '../consts';

export function queryRestaurantRoles({ pageNo = 1, pageSize = 10 }) {
  return GET(`${API_PREFIX}/v1/roles`, {
    page: pageNo,
    page_size: pageSize,
  });
}

export function queryRoleDetail(roleId) {
  return GET(`${API_PREFIX}/v1/role/${roleId}`);
}

export function queryRoleStatus() {
  return GET(`${API_PREFIX}/v1/roles/with-status`);
}

export function createRole(data) {
  return POST(`${API_PREFIX}/v1/role`, data);
}

export function updateRole({ id, ...data }) {
  return PUT(`${API_PREFIX}/v1/role/${id}`, data);
}

export function deleteRole(id) {
  return DELETE(`${API_PREFIX}/v1/role/${id}`);
}
