import { queryRestaurantRoles, queryRoleStatus } from '../services/role';
import {
  activateRestaurantEmployee,
  createRestaurantEmployee,
  deactivateRestaurantEmployee,
  deleteEmployee,
  getEmployeesByDepartmentId,
  getUnassignedDepartmentEmployees,
  queryMembersByRoleId,
  queryRestaurantEmployees,
  updateRestaurantEmployee,
} from '../services/employee';

const BASE = 'TEAM';
const PAGE_SIZE = 100;

export const actions = {
  loadingRoles: `${BASE}_LOADING_ROLES`,
  loadingRolesSuccess: `${BASE}_LOADING_ROLES_SUCCESS`,
  loadingRolesFailed: `${BASE}_LOADING_ROLES_FAILED`,

  loadingMembers: `${BASE}_LOADING_MEMBERS`,
  loadingMemberSuccess: `${BASE}_LOADING_MEMBERS_SUCCESS`,
  loadingMemberFailed: `${BASE}_LOADING_MEMBERS_FAILED`,
};

export const fetchRoles = () => async (dispatch) => {
  dispatch({
    type: actions.loadingRoles,
  });
  let response;

  try {
    response = await queryRestaurantRoles({ pageNo: 1, pageSize: PAGE_SIZE });
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) {
    dispatch({
      type: actions.loadingRolesFailed,
    });
    return;
  }

  dispatch({
    type: actions.loadingRolesSuccess,
    payload: { roles: response.data },
  });
};

export const fetchEmployees = () => async (dispatch) => {
  dispatch({
    type: actions.loadingMembers,
  });

  let response;

  try {
    response = await queryRestaurantEmployees();
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) {
    dispatch({
      type: actions.loadingMemberFailed,
    });
    return;
  }

  dispatch({
    type: actions.loadingMemberSuccess,
    payload: { members: response.data },
  });
};

export const deleteEmployees = (payload, callback) => async (dispatch) => {
  let response;

  try {
    response = await deleteEmployee(payload);
  } catch (e) {
    response = { success: false };
  }

  typeof callback === 'function' && callback(response);

  if (response.success) {
    dispatch(fetchEmployees());
  }
};

export const activateEmployee = (payload, callback) => async (dispatch) => {
  let response;

  try {
    response = await activateRestaurantEmployee(payload);
  } catch (e) {
    response = { success: false };
  }

  typeof callback === 'function' && callback(response);

  if (response.success) {
    dispatch(fetchEmployees());
  }
};

export const deactivateEmployee = (payload, callback) => async (dispatch) => {
  let response;

  try {
    response = await deactivateRestaurantEmployee(payload);
  } catch (e) {
    response = { success: false };
  }

  typeof callback === 'function' && callback(response);

  if (response.success) {
    dispatch(fetchEmployees());
  }
};

export const doCreateMember = (payload, callback) => async (dispatch) => {
  let response;

  try {
    response = await createRestaurantEmployee(payload);
  } catch (e) {
    response = { success: false };
  }

  typeof callback === 'function' && callback(response);

  if (response.success) {
    dispatch(fetchEmployees());
  }
};

export const doUpdateMember = (payload, callback) => async (dispatch) => {
  let response;

  try {
    response = await updateRestaurantEmployee(payload);
  } catch (e) {
    response = { success: false };
  }

  typeof callback === 'function' && callback(response);

  if (response.success) {
    dispatch(fetchEmployees());
  }
};

export const fetchRoleStatuses = (callback) => async () => {
  try {
    const response = await queryRoleStatus();
    if (response.success && typeof callback === 'function') {
      callback(response.data || []);
    }
  } catch (e) {
    if (typeof callback === 'function') {
      callback([]);
    }
  }
};

export const fetchRoleMembers = (roleId, callback) => async () => {
  let response;

  try {
    response = await queryMembersByRoleId(roleId);
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) return;

  if (typeof callback === 'function') {
    callback(response.data || []);
  }
};

export const fetchUnassignedMembers = (callback) => async () => {
  let response;

  try {
    response = await getUnassignedDepartmentEmployees();
  } catch (e) {
    response = { success: false, data: [] };
  }

  if (typeof callback === 'function') {
    callback(response.data || []);
  }
};

export const fetchDepartmentMembers = (departmentId, callback) => async () => {
  let response;

  try {
    response = await getEmployeesByDepartmentId(departmentId);
  } catch (e) {
    response = { success: false, data: [] };
  }

  if (typeof callback === 'function') {
    callback(response.data || []);
  }
};
