import { GET, DELETE, POST, PUT } from '../utils/request';
import { API_PREFIX } from '../consts';

export function getAllDepartments() {
  return GET(`${API_PREFIX}/v1/departments`);
}

export function getDepartmentDetail(id) {
  return GET(`${API_PREFIX}/v1/department/${id}`);
}

export function createDepartment(payload) {
  return POST(`${API_PREFIX}/v1/department`, payload);
}

export function updateDepartment({ id, ...payload }) {
  return PUT(`${API_PREFIX}/v1/department/${id}`, payload);
}

export function deleteDepartment(id) {
  return DELETE(`${API_PREFIX}/v1/department/${id}`);
}
