import {
  createDepartment,
  deleteDepartment,
  getAllDepartments,
  getDepartmentDetail,
  updateDepartment,
} from '../services/department';

const BASE = 'DEPARTMENT';

export const actions = {
  loadingDepartments: `${BASE}_LOADING_DEPARTMENTS`,
  loadDepartmentsSuccess: `${BASE}_LOAD_DEPARTMENTS_SUCCESS`,
  loadDepartmentsFailed: `${BASE}_LOAD_DEPARTMENTS_FAILED`,
};

export const fetchAllDepartments = () => async (dispatch) => {
  dispatch({ type: actions.loadingDepartments });

  let response;

  try {
    response = await getAllDepartments();
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) {
    dispatch({ type: actions.loadDepartmentsFailed });
    return;
  }

  dispatch({
    type: actions.loadDepartmentsSuccess,
    payload: response.data || [],
  });
};

export const fetchDepartmentDetail = (id, callback) => async () => {
  let response;

  try {
    response = await getDepartmentDetail(id);
  } catch (e) {
    response = { success: false };
  }

  typeof callback === 'function' && callback(response);
};

export const doCreateDepartment = (payload, callback) => async (dispatch) => {
  let response;

  try {
    response = await createDepartment(payload);
  } catch (e) {
    response = { success: false };
  }

  typeof callback === 'function' && callback(response);
};

export const doUpdateDepartment = (payload, callback) => async () => {
  let response;

  try {
    response = await updateDepartment(payload);
  } catch (e) {
    response = { success: false };
  }

  typeof callback === 'function' && callback(response);
};

export const doDeleteDepartment = (id, callback) => async () => {
  let response;

  try {
    response = await deleteDepartment(id);
  } catch (e) {
    response = { success: false };
  }

  typeof callback === 'function' && callback(response);
};
